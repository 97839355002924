<template>
  <div>
    <v-dialog v-model="showUpload" width="500">
      <template>
        <Upload url="/product/import" :ignoreStock='ignoreStock' @close="showUpload = false;" @success="showUpload = false; load()"/>
      </template>
    </v-dialog>

    <v-row class="mb-1">
      <v-col>
        <v-btn block type="button" color="primary" v-on:click="exportExcel()">
          EXPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-btn block type="button" color="primary" v-on:click="showUpload = true">
          IMPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-checkbox style="margin: 0px;" v-model="ignoreStock" label="Ignore Stock"></v-checkbox>
      </v-col>

      <v-col>
        <router-link to="manage">
          <v-btn block type="button" color="primary">
            ADD NEW PRODUCT
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <div class="filters">
      <SearchBar ref="searchBar" label='Search for product' @search="filterTable($event)" :defaultValue="search"/>

      <div class="filter">
        <v-select 
          :items="categories" 
          item-text="name" 
          item-value="id"
          v-model="filteredCategory" 
          return-object 
          label="Category" 
          @change="load()" 
          hide-details
          density="compact"/>
      </div>
    </div>

    <v-btn type="button" color="primary" class="mt-2" @click="resetStock()">RESET STOCK FOR FILTERED</v-btn>

    <v-data-table 
      :headers="headers" :items="products"
      :loading="isLoading" :items-per-page="15"
      item-key="id" class="elevation-1 mt-5"
      >

      <template v-slot:[`item.image`]="{ item }">
        <img v-if="item.imageUrlThumbnail != null" :src="imageUrl + item.imageUrlThumbnail" />
      </template>

     <template v-slot:[`item.currentStock`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.currentStock"
          @save="saveCurrentStock(item)"
          large>

          <div>{{ item.currentStock }}</div>
          <template v-slot:input>
            <v-text-field
              v-model="item.currentStock"
              label="Edit"
              :rules="[rules.number]"
              single-line
              autofocus
              @focus="$event.target.select()"
            ></v-text-field>
          </template>

        </v-edit-dialog>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn type="button" color="primary" style="margin-right: 10px; font-size: 10px;" v-if="item.barcode == ''" v-on:click="generateBarcode(item)">
          GENERATE BARCODE
        </v-btn>

        <router-link :to="`manage/${item.id}`">
          <v-icon small class="ml-5">mdi-pencil</v-icon>
        </router-link>
        <v-icon class="ml-5" small @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import SearchBar from '@/components/SearchBar.vue';
  import Upload from '@/components/Upload.vue';

  import { productService, categoryService } from '@/services';
  import { saveAs } from 'file-saver';
  import { b64toBlob } from '@/helpers'
  
  export default {
    name: 'Products',
    components: {
      SearchBar,
      Upload
    },
    data: () => ({
      imageUrl: process.env.VUE_APP_APIURL.substring(0, process.env.VUE_APP_APIURL.length - 3),
      isLoading: false,
      products: [],
      search: "",
      categories: [],
      filteredCategory: null,

      headers: [
        { text: '', align: 'start', sortable: false, filterable: false, value: 'image', width: 80 },
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Buying Price', align: 'start', sortable: true, filterable: true, value: 'buyingPriceExVAT' },
        { text: 'Selling Price', align: 'start', sortable: true, filterable: true, value: 'sellingPrice' },
        { text: 'Margin', align: 'start', sortable: true, filterable: true, value: 'margin' },
        { text: 'Tax %', align: 'start', sortable: true, filterable: true, value: 'taxPercentage' },
        { text: 'Category', align: 'start', sortable: true, filterable: true, value: 'category.name' },
        { text: 'Supplier', align: 'start', sortable: true, filterable: true, value: 'supplier.name' },
        { text: 'Current Stock', align: 'start', sortable: true, filterable: true, value: 'currentStock' },
        // { text: 'Min Stock', align: 'start', sortable: true, filterable: true, value: 'minStock' },
        // { text: 'Max Stock', align: 'start', sortable: true, filterable: true, value: 'maxStock' },
        { text: 'Barcode', align: 'start', sortable: true, filterable: true, value: 'barcode' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],

      showUpload: false,
      ignoreStock: true,
      rules: {
        number: value => {
          if (!isNaN(parseFloat(value)) && isFinite(value)) return true
          return 'Current stock must be a valid number'
        },
      },
      snackbar: {
        show: false,
        text: '',
        color: 'success',
        timeout: 3000
      }
    }),
    created () {
      this.load();
    },
    methods: {
      resetStock() {
        this.$confirm(
        {
          message: `Are you sure you want to reset the stock for ${this.products.length} products?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              var productIds = this.products.map((p) => p.id);
              var result = await productService.resetStock(productIds);
              if (result.status == 200) {
                this.load();
              }
            }
          }
        })
      },

      showSnackbar(text, color = 'success') {
          this.snackbar.text = text;
          this.snackbar.color = color;
          this.snackbar.show = true;
      },

      saveCurrentStock: async function(item) {
        try {
          this.isLoading = true;
          const response = await productService.updateCurrentStock(item.id, item.currentStock);
          if (response.status === 200) {
            this.showSnackbar('Current stock updated successfully', 'success');
          } else {
            throw new Error('Failed to update current stock');
          }
        } catch (error) {
          console.error('Error updating current stock:', error);
          this.showSnackbar('Failed to update current stock', 'error');
          await this.load();
        } finally {
          this.isLoading = false;
        }
      },

      filterTable: function(search) {
        this.search = search;
        localStorage.setItem("@projects-filters", search);
        this.load();
      },
      load: async function() {
        this.isLoading = true;
        this.categories = await categoryService.get();
        this.products = await productService.get();

        var search = localStorage.getItem("@projects-filters", search);

        if (this.filteredCategory != null) {
          this.products = this.products.filter(p => p.categoryId == this.filteredCategory.id);
        }

        if (search && search.trim() !== "") {
          this.search = search;
          this.products = this.products.filter(product => {
            // Convert both the search term and product properties to lowercase for case-insensitive search
            const searchLower = search.toLowerCase();
            return (
              product.name?.toLowerCase().includes(searchLower) ||
              product.category?.name.toLowerCase().includes(searchLower) ||
              product.supplier?.name.toLowerCase().includes(searchLower) ||
              product.barcode?.toLowerCase().includes(searchLower)
            );
          });
        }

        this.isLoading = false;
      },
      remove: async function(product) {
        this.$confirm(
        {
          message: `Are you sure you want to delete this product?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await productService.remove(product.id);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error deleting");
                return;
              }

              this.load();
            }
          }
        })
      },
      generateBarcode: function(product) {
        this.$confirm(
        {
          message: `Are you sure you want to generate a barcode?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await productService.generateBarcode(product);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error generating barcode");
                return;
              }

              this.load();
            }
          }
        })
      },
      exportExcel: async function() {
        this.isLoading = true;
        var response = await productService.exportExcel();
        if (response.status != 200) {
          alert("Error exporting");
          return;
        }

        var base64 = await response.json();
        const blob = b64toBlob(base64, 'application/vnd.ms-excel');
        saveAs(blob, 'Products.xls');

        this.isLoading = false;
      },
    }
  }
</script>
